import fetch from "isomorphic-unfetch"
import queryString from "query-string"
import { theme } from "./../themes/theme_generator"

/**
 * These calls rely on
 * @param {*} query
 * @returns
 */
export const fetchSiteData = async query => {
  // TODO no point fetching all of these for all sites if they're not used
  return await Promise.allSettled([
    fetchData("taxonomies"),
    fetchData("suitabilities"),
    fetchData("send_needs"),
    fetchData("accessibilities"),
  ])
    .then(data => {
      // console.log("returned from fatching all data", data)
      return data.map(results => {
        if (results.status === "fulfilled") {
          return results.value
        } else {
          return []
        }
      })
    })
    .catch(error => {
      // if there's an error, log it
      console.log(error)
      // return what the site expects so that filters just don't show
      return [...Array(4)].map(x => [])
    })
}

/**
 * Get service data
 * @param {*} query
 * @returns
 */
export const fetchServiceData = async query => {
  const per_page = 20

  let {
    keywords,
    location,
    lat,
    lng,
    collection,
    categories,
    needs,
    accessibilities,
    suitabilities,
    days,
    min_age,
    max_age,
    only,
    page,
  } = queryString.parse(query)

  // api expects collections and categories to both be a "taxonomy" parameter
  let taxonomies = []
  if (collection) taxonomies.push([].concat(collection))
  if (categories) taxonomies.push([].concat(categories).join(","))

  let targetDirectories = []
  if (theme.targets.length > 0)
    targetDirectories.push([].concat(theme.targets).join(","))

  try {	  
	let textParam = '';
	if (keywords){
		textParam = '&text='+keywords;
	}
    const res = await fetch(
      `${process.env.REACT_APP_API_HOST}/services?include=service_at_locations&${queryString.stringify({
        targetDirectories,
        keywords,
        location,
        lat,
        lng,
        taxonomies,
        needs,
        accessibilities,
        suitabilities,
        days,
        min_age,
        max_age,
        only,
        page,
        per_page,
      })}`+textParam
    )
    return fixJSON(await res.json());

  } catch (err) {
    console.log(err)
  }
}

export const fixJSON = json =>{
  if (json){
    if (json.content){
      json.content.forEach(element => {
        element = fixSingleServiceData(element);
      });
    }
    else{
      json = fixSingleServiceData(json);
    }
  }
  return json;
}

const fixSingleServiceData = element => {
  if (element.hasOwnProperty("organization"))
  {    
    element.organisation = element.organization;
  }
  if (element.hasOwnProperty("regular_schedules"))
  { 
    element.regular_schedules = fixRegularScheduleData(element.regular_schedules);
  }
  if (!element.hasOwnProperty("links"))
  {  
    element.links = [];
  }
  if (!element.hasOwnProperty("taxonomies"))
  {
    element.taxonomies = [];
    if (element.hasOwnProperty("service_taxonomys"))
    {
      for(const st of element.service_taxonomys)
      {
        element.taxonomies.push(st);
      }
    }
  }
  if (!element.hasOwnProperty("locations"))
  {    
    element.locations = [];
    if (element.hasOwnProperty("service_at_locations"))
    {
      for(const sal of element.service_at_locations)
      {        
        if (sal.hasOwnProperty("regular_schedule"))
        { 
          element.regular_schedule = fixRegularScheduleData(sal.regular_schedule);
        }
        var location = sal.location;
        if (!location.hasOwnProperty("geometry"))
        {
          location.geometry = {};
          location.geometry.coordinates = [];
          if (location.hasOwnProperty("longitude"))
          {
            location.geometry.coordinates.push(location.longitude);
          }
          if (location.hasOwnProperty("latitude"))
          {
            location.geometry.coordinates.push(location.latitude);
          }           
        }
        element.locations.push(location);     
      }
    }
  }  
  return element;
}

const fixRegularScheduleData = schedules => {
  for(const schedule of schedules)
  { 
    if (!schedule.hasOwnProperty("weekday"))
    {
      schedule.weekday = schedule.byday;
    }
  }
  return schedules;
}

/**
 * Fetching data from outpost itself vs the ol' static files method
 * @param {string} resource
 * @returns {}
 */
export const fetchData = async resource => {
  const directory = [theme.slug]

  try {
    const res = await fetch(
      `${
        process.env.REACT_APP_FILTERS_DATASOURCE
      }/${resource}?${queryString.stringify({
        directory,
      })}`
    )
    return await res.json()
  } catch (err) {
    console.log(err)
    return []
  }
}
